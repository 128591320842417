import React from 'react';
import { Link } from 'gatsby';

import './header.scss';

function Header() {
  return  (
    <header className="header">
      <nav>
        <div>
          <Link to="/">
            <button className="header__logo">
              mike mccready
            </button>
          </Link>
        </div>

        <Link to="#work">
          <button className="header__link">
            recent work
          </button>
        </Link>
      </nav>
    </header>
  )
}

export default Header;

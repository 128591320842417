import React from 'react';

import Header from '../Header';
import Floater from '../Floater';

import './gatsby.css';
import './layout.scss';

function App(props) {
  return (
    <>
      <Header siteTitle="Mike McCready _ Software Development / Experience Design" />
      <main>{props.children}</main>
      <Floater />
      <footer>
        © Mimic Co. {new Date().getFullYear()}
      </footer>
    </>
  )
}

export default App;

import React from 'react';
import { FaAngellist, FaLinkedinIn, FaGithub } from 'react-icons/fa';

function Floater() {
  return (
    <>
      <div className="floater">
        <a href="https://angel.co/mikemccready" target="_blank">
          <button>
            <FaAngellist />
          </button>
        </a>
        <a href="https://www.linkedin.com/in/mmccready/" target="_blank">
          <button>
            <FaLinkedinIn />
          </button>
        </a>
        <a href="https://github.com/mikemccready" target="_blank">
          <button>
            <FaGithub />
          </button>
        </a>
      </div>
    </>
  );
}

export default Floater;
